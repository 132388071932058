import { useEffect, useLayoutEffect, useRef, useCallback, useMemo } from 'preact/hooks';
import classnames from 'classnames';

import { useGlobalContext, ActionType } from './hooks/use-global-context';

import Nav from './Nav';
import Welcome from './Welcome';
import Account from './Account';
import DataCollector from './DataCollector';
import QrCode from './QrCodeGenerator';
import Success from './Success';
import GoogleSignIn from './GoogleSignIn';
import useDynamicMobileHeight from './hooks/use-dynamic-mobile-height';
import useHandleClose from './hooks/use-handle-close';
import useBottomSheet from './hooks/use-bottom-sheet';
import PoweredByRownd from './Components/PoweredByRownd/PoweredByRownd';
import InitDomListeners from './InitDomListeners';
import { Suspense, lazy } from 'preact/compat';

const OidcLazy = lazy(() => import('@/scripts/Oidc'));

export default function Container() {
  const containerEl = useRef<HTMLDivElement>(null);

  useDynamicMobileHeight();
  const { state, dispatch } = useGlobalContext();
  const { isBottomSheetEnabled } = useBottomSheet();
  const handleClose = useHandleClose();
  const { nav, is_container_visible: isContainerVisible, app } = state;
  const { current_route, route_trigger, event_id, popup_route } = nav;
  const blurBackground =
    typeof app?.config?.hub?.customizations?.blur_background === 'boolean'
      ? app?.config?.hub?.customizations?.blur_background
      : true;
  const isMobile = state.config?.displayContext === 'mobile_app';

  const prevRoute = useRef('');
  const lastNavEventId = useRef('');

  const setIsContainerVisible = useCallback(
    (isVisible: boolean) => {
      if (!isVisible) {
        handleClose();
        return;
      }
      dispatch({
        type: ActionType.SET_CONTAINER_VISIBLE,
        payload: {
          isVisible,
        },
      });
    },
    [dispatch, handleClose]
  );

  // Opens / closes based on nav event
  useEffect(() => {
    if (route_trigger !== 'nav' || !event_id || event_id === lastNavEventId.current) {
      return;
    }

    prevRoute.current = current_route;
    lastNavEventId.current = event_id;
  }, [current_route, route_trigger, event_id]);

  // Toggle rounded edges depending on height
  useLayoutEffect(() => {
    if (state.use_modal || !containerEl.current) {
      return;
    }

    const containerHeight = containerEl.current.clientHeight;

    if (containerHeight > 100) {
      containerEl.current.classList.add('rph-container-tall');
    } else {
      containerEl.current.classList.remove('rph-container-tall');
    }
  }, [containerEl?.current?.clientHeight, state.use_modal]);

  /**
   * Set the background to inert when the modal is open
   * and remove the inert attribute when the modal is closed
   *
   * This is used to prevent the background from being interacted with when the modal is open
   */
  const setBackgroundInert = useCallback((inert: boolean) => {
    const rphContainer = document.getElementById('rownd-privacy-hub');
    if (!rphContainer) return;
    document.body.childNodes.forEach((child) => {
      if (child !== rphContainer && child.nodeType === Node.ELEMENT_NODE) {
        (child as HTMLElement).inert = inert;
      }
    });
  }, []);

  useEffect(() => {
    setBackgroundInert(state.is_container_visible && state.use_modal);
  }, [state.is_container_visible, state.use_modal, setBackgroundInert]);

  useEffect(() => {
    if (state.use_modal) {
      document.body.classList.add('rph-modal-open');
    } else {
      document.body.classList.remove('rph-modal-open');
    }
  }, [state.use_modal]);

  const placement = useMemo((): any => {
    if (state.app.config === null) return 'hidden';
    return app.config?.hub?.customizations?.placement || 'bottom-left';
  }, [app.config?.hub?.customizations?.placement, state.app.config]);

  useEffect(() => {
    // Import Lottie package 2secs after Hub loads
    const loadLottie = async () => {
      await import('lottie-web');
    };
    setTimeout(() => {
      loadLottie();
    }, 2000);
  }, []);

  const currentRoute = useMemo(() => (popup_route ? '' : current_route), [current_route, popup_route]);

  return (
    <div id="rownd-privacy-hub" className={`cleanslate rph-placement-${placement}`} data-turbo-permanent="true" data-turbolinks-permanent="true">
      {!isMobile && !isBottomSheetEnabled && <Nav />}
      {!isMobile && <InitDomListeners />}
      <div
        ref={containerEl}
        className={classnames('rph-container', {
          'rph-visible': isContainerVisible,
          'rph-modal-container': state.use_modal,
          'rph-bottom-sheet': isBottomSheetEnabled,
          'rph-modal__disable-blur': state.use_modal && !blurBackground,
          [`rph-section-${state.nav.section}`]: !!state.nav.section,
        })}
      >
        {popup_route?.startsWith('/powered') && <PoweredByRownd />}
        {currentRoute.startsWith('/welcome') && <Welcome setIsVisible={setIsContainerVisible} />}
        {currentRoute.startsWith('/account') && <Account />}
        {currentRoute.startsWith('/questions') && <DataCollector setContainerVisible={setIsContainerVisible} />}
        {currentRoute.startsWith('/qrcode') && <QrCode />}
        {currentRoute.startsWith('/success') && <Success />}
        {currentRoute.startsWith('/google') && <GoogleSignIn />}
        {currentRoute.startsWith('/oidc') && (
          <Suspense fallback={null}>
            <OidcLazy />
          </Suspense>
        )}
      </div>
    </div>
  );
}
